.wrapper {
  :global {
    h2 {
      margin-bottom: 0.1em !important;
      .sub {
        font-size: 0.9rem !important;
        margin-top: 0.3em !important;
      }
      i {
        color: #854295;
        font-size: 1rem;
      }
    }
  }
}

.footer {
  text-align: center;
  font-size: 11px;
  margin-top: 4em;
  div {
    height: unset;
    &,
    i,
    p {
      background-color: transparent;
      display: inline;
    }
  }
}

@media only screen and (max-width: 767px) {
  thead {
    display: none !important;
  }
}
