.wrapper {
    padding: 20px 150px;
    background-color: #f7f7f7;
}

.loader {
    width: 100vw;
    height: 100vh;
}

@media only screen and (max-width: 767px) {
    .wrapper {
        padding: 20px 10px;
    }
}
